<template>
    <v-sheet>
        <v-row>
          <v-col cols="12" class="pl-0 pt-0 pb-0">
              <tile-search-date
                init-type="WEEK"
                @changed="searchDateChanged"
                @inited="searchDateInited"
                ref="date_range_select"
                :from-day="searchParam.search_start"
                :to-day="searchParam.search_end"
            ></tile-search-date>
            <v-btn
              class="mx-2"
              fab
              color="#F4F6F8"
              elevation="0"
              @click="doSearch(1)"
              style="float:left;"
            >
              <v-icon color="#A5ACB4">
                mdi-magnify
              </v-icon>
            </v-btn>

          </v-col>
        </v-row>
        <v-row>
            <v-col class="text-right" style="font-size:20px; color:#555555;">통화량 : {{totalCount}} 건</v-col>
        </v-row>
        <v-card outlined>
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr style="background-color:#F4F6F8; height:78px; color:#333333; font-weight:600;">
                        <th class="text-left text-h6 pl-6">
                            수신일시
                        </th>
                        <th class="text-left text-h6">
                            발신번호
                        </th>
                        <th class="text-left text-h6">
                            수신자
                        </th>
                        <th class="text-center text-h6">
                            통화결과
                        </th>
                        <th class="text-center text-h6">
                            통화시간
                        </th>
                        <th class="text-center text-h6 pr-6" v-if="mainAdminYn == 'Y'">
                            녹취
                        </th>
                    </tr>
                </thead>
                <tbody style="color:#555555;">
                    <template v-for="(statItem, statIdx) in statList">
                    <tr style="height:68px;" :key="statIdx">
                        <td class="text-left pl-6" style="font-size:20px;">{{statItem.call_start_dt | dateMin}}</td>
                        <td class="text-left" style="font-size:20px;">{{statItem.remote_mdn | phoneNum}}</td>
                        <td class="text-left" style="font-size:20px;">{{receiveText(statItem)}}</td>
                        <td class="text-center" style="font-size:20px;">{{callResultText(statItem.call_result)}}</td>
                        <td class="text-right pr-6" style="font-size:20px;">{{statItem.call_billsec_min | number}} 분</td>
                        <td class="text-center pr-6" style="font-size:20px;" v-if="mainAdminYn == 'Y'">
                            <v-btn small v-if="mainAdminYn == 'Y' && statItem.record_yn == 'Y' && statItem.record_result == 'DONE' && statItem.record_cont_seqno > 0" @click="downloadRecord(statItem)" outlined color="green">다운로드</v-btn>
                            <span v-else>-</span>
                        </td>
                    </tr>
                    </template>
                    <tr style="height:69px;" v-if="!statList || statList.length == 0">
                        <td class="text-center" style="font-size:20px;" colspan="5">조회된 결과가 없습니다</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <v-row>
            <v-col cols="2" class="text-left">
                <v-btn v-on:click="downloadExcel" :loading="excelLoading">DOWNLOAD</v-btn>
            </v-col>
            <v-col cols="10" class="text-right">
                <tile-paging :pageNo="searchParam.page_no" :totalPageCount="totalPageCount" @goPage="doSearch"></tile-paging>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import svcHistApi from '@/api/svc_hist'


export default {
    name: 'svc_hist_recv_call_list',
    components: {
        'TilePaging': () => import('@/components/common/tile_paging.vue'),
        'TileSearchDate': () => import('@/components/common/tile_search_date.vue'),
    },
    data: () => ({
        searchParam: {
            search_start: '',
            search_end: '',
            page_no: 1,
            page_size: 10
        },
        initFlags: {'search_date': false},
        excelLoading: false,
    }),
    created () {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'svc_hist_recv')        
        
        let lastMenuId = this.$store.getters['lastMenuId']            
        if(lastMenuId == 'stats_recv' && this.curSearchParam) { 
            Object.assign(this.search, this.curSearchParam)
        }
    },
    computed: {
        ...mapGetters({
            mainAdminYn: 'login/getMainAdminYn',
        }),
        ...mapState('svc_hist_recv', {
            curSearchParam: state => state.searchParam,
            totalCount: state => state.totalCount,
            statList: state => state.statList,
        }),
        totalPageCount() {
            if(this.totalCount == 0){ return 0 }        
            var pageSize = (this.searchParam && this.searchParam.page_size) ? this.searchParam.page_size : 25
            var cnt = Math.floor(this.totalCount / pageSize) + (((this.totalCount % pageSize) > 0) ? 1 : 0)
            return cnt
        }, 
        isLoaded () { 
            if(this.statList) { return true }
            return false
        }
    },  
    methods : { 
        searchDateInited(payload) { 
            if(payload && payload.from_day) {  this.searchParam.search_start = payload.from_day }
            if(payload && payload.to_day) {  this.searchParam.search_end = payload.to_day }
            this.initFlags['search_date'] = true
            this.doSearch()
        },        
        searchDateChanged(payload) { 
            if(payload && payload.from_day) { this.searchParam.search_start = payload.from_day  }
            if(payload && payload.to_day) { this.searchParam.search_end = payload.to_day  }
        },          
        doSearch (no) { 
            if(no) {
                this.searchParam.page_no = no
            }
            if(!this.initFlags.search_date){
                return
            }

            this.$store.dispatch('svc_hist_recv/getStatList', {
                searchParam: this.searchParam, 
                scb: (stat_list, total_count, va) => { 
                }, 
                fcb: (err, va) => { 
                }, 
                va: this
            })
        },
        callResultText (c) { 
            if(c == 'SUCC') { 
                return '통화'
            } else if(c == 'MISS') { 
                return '미응답'
            } else if(c == 'REJECT') { 
                return '수신거부'
            }
            return c
        },
        receiveText (c) { 
            var ret = ''

            if(c.related_menu_name && c.user_name) { 
                ret = c.related_menu_name + ' ( ' + c.user_name + ' )'
            } else if(!c.related_menu_name && c.user_name) { 
                ret = c.user_name
            } else if(c.related_menu_name && !c.user_name) { 
                ret = c.related_menu_name
            }
            return ret
        },
        downloadRecord(item) {
            if(!item || !item.record_cont_seqno) { return }
            let va = this
            va.$store.dispatch('openConfirm', {
                message: '녹취 데이터를 다운로드 하시겠습니까?', 
                va: va,
                okCb: function(va) {    
                    svcHistApi.downloadRecord({seq: item.record_cont_seqno, tm: item.call_start_dt, mdn: item.remote_mdn })
                }
            })                        
        },
        downloadExcel () { 
            var va = this
            va.$store.dispatch('openConfirm', {
                message: '수신 통화 이력 목록을 엑셀 파일로 다운로드하시겠습니까?', 
                va: va,
                okCb: function(va) {    
                    va.excelLoading = true
                    svcHistApi.downloadExcel({search_start: va.searchParam.search_start, search_end: va.searchParam.search_end, call_type: 'INB_RECV'},
                        (va) => { 
                            va.excelLoading = false
                            // SUCC
                            va.$store.dispatch('openAlert', {message: '엑셀 파일이 다운로드 되었습니다',})
                        }, (err, va) => { 
                            va.excelLoading = false
                            // FAIL
                            va.$store.dispatch('openAlert', {message: '엑셀 파일이 다운로드를 실패 했습니다',})
                        }, va
                    )
                }
            })            
        }        
    }
}
</script>